import React, { useContext } from 'react'
import PageContext from '@context'
import SbEditable from 'storyblok-react'
import {
  Box,
  ListItem,
  ListItemText,
  ListItemIcon,
  useMediaQuery,
  Typography,
} from '@material-ui/core'
import { Link as GatsbyLink } from 'gatsby'
import { Img } from '@system'
import renderBloks from '@renderBloks'
import { makeStyles } from '@material-ui/styles'
import ACC_THEME from '@themes'
import get from 'lodash/get'
import renderBlok from '@renderBlok'
import multilinkToUrl from '@helpers/multilink-to-url'
import { H6 } from '@system'

const useStyles = makeStyles((theme) => ({
  listIconTitle: {
    alignItems: 'flex-start',
    flexDirection: 'column',
    margin: ({ handHeldDevice }) => handHeldDevice && '24px 0',
    padding: '0px',
  },
  listIconBox: {
    minWidth: '40px',
    marginRight: '1rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    '& a': {
      display: 'inline-block',
      height: (props) => props.mobileIconDimension,
    },
  },
  listItemText: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: '16px',
    fontSize: '11px',
    color: theme.palette.text.iris,
    flex: '0 1 auto',
    marginTop: '0px',
    textTransform: 'uppercase',
  },
  mobileHeader: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: '16px',
    fontSize: '13.5px',
    color: theme.palette.text.white,
    flex: '0 1 auto',
    marginTop: '0px',
    textDecoration: 'none',
    display: 'inline-block',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  link: {
    margin: '0 0 0 10px',
  },
  links: {
    margin: '4px 0 4px 0',
  },
  productLink: {
    '& img:hover': {
      transform: 'scale(1.05)',
      transformOrigin: 'top left',
    },
  },
}))

const TopNavItemWithIconAndDescription = (props) => {
  const isInEditor = useContext(PageContext).isInEditor
  const handHeldDevice = useMediaQuery(ACC_THEME.breakpoints.down('sm'))
  const mobileIconDimension = useMediaQuery(ACC_THEME.breakpoints.down('sm'))
    ? 20
    : 40
  const classes = useStyles({ ...props, handHeldDevice, mobileIconDimension })
  const multilink = get(props.blok, 'productLink[0].link')
  const url = !!multilink && multilinkToUrl(multilink, isInEditor)
  const icon =
    (typeof props.blok.icon === 'object' && props.blok.icon.filename) ||
    props.blok.icon
  const headerLink = props.blok.productLink
  const mobileHeaderLink = get(props.blok, 'productLink[0].textToHyperlink')

  return (
    <SbEditable key={props.blok._uid} content={props.blok}>
      {!handHeldDevice ? (
        <ListItem className={classes.listIconTitle}>
          {typeof icon !== 'object' && headerLink.length !== 0 ? (
            <ListItemIcon className={classes.listIconBox}>
              <GatsbyLink style={{ display: 'inline-block' }} to={url}>
                <Img
                  style={{
                    display: 'block',
                    height: mobileIconDimension,
                    width: mobileIconDimension,
                    objectPosition: 'center center',
                  }}
                  src={icon}
                  alt="Autodesk Construction Cloud Product"
                />
              </GatsbyLink>
            </ListItemIcon>
          ) : typeof icon !== 'object' && headerLink.length === 0 ? (
            <ListItemIcon className={classes.listIconBox}>
              <Img
                style={{
                  display: 'block',
                  height: mobileIconDimension,
                  width: mobileIconDimension,
                  objectPosition: 'center center',
                }}
                src={icon}
                alt="Autodesk Construction Cloud Product"
              />
            </ListItemIcon>
          ) : (
            <></>
          )}

          {headerLink.length !== 0 ? (
            <ListItemText
              className={classes.productLink}
              style={{ marginTop: 0 }}
            >
              <H6 className={classes.listItemText}>{props.blok.linkText}</H6>
              {renderBloks(props.blok.productLink)}
            </ListItemText>
          ) : (
            <H6 className={classes.listItemText}>{props.blok.linkText}</H6>
          )}

          <Box className={classes.link}>
            {props.blok.subMenuIconLinks.map((link) => (
              <Box className={classes.links} key={link._uid}>
                {renderBlok(link)}
              </Box>
            ))}
          </Box>
        </ListItem>
      ) : (
        <ListItem className={classes.listIconTitle}>
          <H6 className={classes.listItemText}>{props.blok.linkText}</H6>
          <Box>
            {mobileHeaderLink && (
              <GatsbyLink className={classes.mobileHeader} to={url}>
                {mobileHeaderLink}
              </GatsbyLink>
            )}
            {props.blok.subMenuIconLinks.map((link) => (
              <Box mb={1} key={link._uid}>
                {renderBlok(link, { isNav: true })}
              </Box>
            ))}
          </Box>
        </ListItem>
      )}
    </SbEditable>
  )
}

export default TopNavItemWithIconAndDescription
